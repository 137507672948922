import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/category/list.php',
    method : 'post',
    data : data
  })
}


//删除
export function deleteCategory(data) {
  return request({
    url : '/category/delete.php',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteCategory(data) {
  return request({
    url : '/category/deleteBatch.php',
    method : 'post',
    data : data
  })
}

//新增 json
export function createCategory(data) {
  return request({
    url : '/category/saveCategory.php',
    method : 'post',
    data : data
  })
}

//新增 formdata
export function createCategoryForm(form) {
  const data = form
  return request({
    url : '/category/save.php',
    method: 'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    data
  })
}

//修改 JSon
export function updateCategory(data) {
  return request({
    url :'/category/updateCategory.php',
    method : 'post',
    data : data
  })
}

//修改 formdata
export function updateCategoryForm(form) {
  const data = form
  return request({
    url :'/category/update.php',
    method: 'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    data
  })
}

//全部
export function fetchAllCate() {
  return request({
    url : '/category/all.php',
    method : 'get',
  })
}

//全部一级
export function fetchAllParentCate() {
  return request({
    url : '/category/allParent.php',
    method : 'get',
  })
}

//全部一级
export function fetchAllCateWithChildren() {
  return request({
    url : '/category/allCateWithChildren.php',
    method : 'get',
  })
}
